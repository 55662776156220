import {useState} from 'react';
import cx from 'classnames';
import Link from 'next/link';
import {CaretDown} from '@polarsignals/icons';

const items = [
  {
    question: 'Does Polar Signals add any overhead to my application?',
    answer:
      'Polar Signals adds a negligible overhead to your application. The overhead is typically less than 1% and is dependent on the language and the application. The overhead is due to the collection of the profiling data. The overhead is not dependent on the amount of data sent to Polar Signals Cloud for profiling.',
  },
  {
    question: 'Which languages are supported?',
    answer: (
      <div>
        Polar Signals supports Go, C++, C, Java, Node.JS, Python, Ruby, and Rust applications, with
        additional language support coming soon. You can find a list of all supported languages in
        the{' '}
        {
          <span className="text-brand-dark-blue cursor-pointer hover:opacity-80">
            <Link href="https://www.polarsignals.com/docs/overview/#i-already-use-polar-signals-cloud">
              Polar Signals Cloud Documentation
            </Link>
          </span>
        }
        .
      </div>
    ),
  },
  {
    question: 'Can I alert on applications getting worse?',
    answer: (
      <div>
        Weekly reporting that identifies workloads that have changed significantly week over week is
        coming soon. In the meantime, we have{' '}
        <a
          className="text-brand-dark-blue cursor-pointer hover:opacity-80"
          target="_blank"
          rel="noreferrer noopener"
          href="https://github.com/polarsignals/profile-exporter"
        >
          profile-exporter,
        </a>{' '}
        a tool that can export data to a Prometheus-compatible system from where one can alert.
      </div>
    ),
  },
  {
    question: 'How is the pricing determined?',
    answer:
      'The pricing is determined based on the amount of data sent to Polar Signals Cloud for profiling. Customers are billed according to the pricing tiers, with each tier representing 10GB of data.',
  },
  {
    question: 'What is the basis for calculating the monthly billing?',
    answer:
      'The monthly billing is calculated based on the total amount of data sent for profiling during the billing period. Customers are billed per 10GB tiers, and the number of tiers is rounded up to the nearest whole number.',
  },
  {
    question: 'Are there any additional costs or fees associated with using Polar Signals Cloud?',
    answer:
      'No, there are no additional costs or fees associated with using Polar Signals Cloud. We also do not charge per additional seats. The pricing is transparent and includes all the features and functionalities.',
  },
  {
    question: 'How far back can I query profiled data?',
    answer: 'Currently, the data can be queried up to 30 days.',
  },
  {
    question: 'Is there a discount for high-volume data profiling?',
    answer: (
      <div>
        Reach out to{' '}
        {
          <a
            className="text-brand-dark-blue cursor-pointer hover:opacity-80"
            href="mailto:sales@polarsignals.com"
          >
            sales@polarsignals.com
          </a>
        }{' '}
        for more information.
      </div>
    ),
  },
  {
    question: 'How often is the billing cycle?',
    answer: (
      <div>
        The billing cycle is monthly, and the start date is dependent on when the subscription
        begins. At the end of the billing cycle, we calculate the total amount of data sent for
        profiling and generate the corresponding invoice and charge the credit card on file. If
        you’d like to change the cycle date, you can reach out to{' '}
        {
          <a
            className="text-brand-dark-blue cursor-pointer hover:opacity-80"
            href="mailto:support@polarsignals.com"
          >
            support@polarsignals.com
          </a>
        }
        .
      </div>
    ),
  },
  {
    question: 'Does profiling data contain sensitive information?',
    answer:
      'No, profiling data are just statistics about source code. It may contain function names, filenames, and line numbers, but not code itself, unless users choose to explicitly upload source code for extended functionality.',
  },
  {
    question: 'Is the data in Polar Signals Cloud encrypted at rest?',
    answer: 'Yes, the data in Polar Signals Cloud is encrypted at rest.',
  },
];

const FAQItems = ({
  items,
}: {
  items: {question: string; answer: string | JSX.Element | JSX.Element[]}[];
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const selectedItem = items[selectedIndex];
  return (
    <div className="grid grid-cols-12">
      {items.map((item, index) => (
        <div
          key={index}
          className={cx(
            'col-start-0 col-span-12 overflow-hidden',
            selectedItem === item && 'selected opacity-100',
            'border-t border-solid border-[#797979] last:border-b'
          )}
        >
          <div
            className="flex cursor-pointer items-center justify-between py-4 font-sans text-base text-gray-800 hover:opacity-80 lg:text-lg"
            onClick={() => setSelectedIndex(selectedIndex !== index ? index : -1)}
          >
            <div className="flex-shrink lg:w-[754px]">{item.question}</div>
            <div className="flex flex-grow justify-end">
              <CaretDown
                className={cx(selectedItem === item && 'rotate-180 transform')}
                width={32}
                height={32}
              />
            </div>
          </div>
          <div
            className={cx(
              'pb-4 font-sans text-base text-gray-500 lg:w-[754px] lg:text-lg',
              selectedItem !== item && 'h-0 pb-0'
            )}
          >
            {item.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

const FAQSection = () => (
  <section id="faq" className="bg-white">
    <div className="container mx-auto flex flex-col items-center justify-center gap-12 px-4 py-12 lg:w-[786px] lg:py-32">
      <div className="text-semibold text-2.5xl text-center text-gray-900 lg:text-[40px]">
        Frequently asked questions
      </div>
      <FAQItems items={items} />
    </div>
  </section>
);

export default FAQSection;
